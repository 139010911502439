@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Hachi+Maru+Pop&display=swap');

html {
    cursor: url(../../public/img/cursor/default.png), auto;
    transform: scaleY(-1);
}

body {
    margin: 0;
    background-color: #bbd2b5;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.hachi-maru-pop {
    font-family: "Hachi Maru Pop", cursive;
    font-style: normal;
}

a {
    font-family: sans-serif;
}

a:hover {
    text-decoration: underline;
    cursor: url(../../public/img/cursor/hover.png), auto;
}

@layer components {
    .pc-border {
        @apply rounded-3xl border-8 border-white;
    }

    .skin-img {
        @apply w-fit h-fit object-cover block mx-auto pt-5 pr-4 pl-8 pb-3;
    }

    .card {
        @apply bg-profilecard w-fit my-10 mx-auto pc-border shadow-2xl;
    }

    .nuke2-images {
        @apply w-fit m-5;
    }

    .nuke2-images img {
        @apply w-64 sm:w-48 pc-border shadow-lg;
    }

    ::selection {
        @apply bg-lime-400 text-white;
    }

    ::-moz-selection {
        @apply bg-lime-400 text-white;
    }

    .col-span-1 {
        @apply h-14;
    }

    .hover-btn-border-color {
        border-color: #00ff00;
    }

    .header-img {
        @apply w-8;
    }
}